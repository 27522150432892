import { graphql } from 'gatsby';
import * as React from 'react';
import LayoutHeaderAndFooter from '../components/layout-header-and-footer';
import { padding10 } from '../styles/class-names';
import { formatDateString } from '../utils/date';
import Seo from '../components/seo';

export default function Writing({ data }: { data: any }) {
	const post = data.markdownRemark;
	const image = data.site.siteMetadata.siteUrl + '/' + post.frontmatter.socialCard;
	const url = data.site.siteMetadata.siteUrl + post.fields.slug;
	return (
    <LayoutHeaderAndFooter>
			<Seo title={post.frontmatter.title} description={post.excerpt} imagePath={image} url={url}/>
			<h1 className={padding10}>{post.frontmatter.title}</h1>
			<time className={padding10}>{ formatDateString(post.frontmatter.date)}</time>
			<article className={padding10} dangerouslySetInnerHTML={{ __html: post.html }}></article>
    </LayoutHeaderAndFooter>
	);
}

export const query = graphql`
	query($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			excerpt
			html
			fields {
				slug
			}
			frontmatter {
				date
				socialCard
				title
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
